export type InputObject = {
  key: string;
  value: string;
};

export const regions = [
  {
    key: '',
    value: 'All',
    languages: [
      'en-AU,en-US,en-GB',
      'sv-SE',
      'de-DE',
      'fr-FR,fr-CA',
      'es-ES,es-XL,es-SA',
      'el-GR',
      'it-IT',
      'ro-RO',
      'pt-PT',
      'pt-BR',
      'sk-SK',
      'nl-NL',
      'da-DK',
      'no-NO',
      'fi-FI',
      'pl-PL',
      'hu-HU',
      'hr-HR',
      'cs-CZ',
      'et-EE',
      'lv-LV',
      'sl-SI',
      'lt-LT',
      'bg-BG',
      // 'ru-RU',
      'tr-TR',
      'zh-CN',
      'ko-KR',
      'ar-EG',
      'sr-Latn-RS',
    ],
  },
  {
    key: 'AS',
    value: 'Asia and Pacific',
    languages: ['en-GB,en-US', 'zh-CN', 'ko-KR'],
  },
  { key: 'AU', value: 'Australia', languages: ['en-AU,en-US,en-GB'] },
  {
    key: 'EU',
    value: 'Europe',
    languages: [
      'en-GB,en-US',
      'sv-SE',
      'de-DE',
      'fr-FR',
      'es-ES',
      'el-GR',
      'it-IT',
      'ro-RO',
      'pt-PT',
      'sk-SK',
      'nl-NL',
      'da-DK',
      'no-NO',
      'fi-FI',
      'pl-PL',
      'hu-HU',
      'hr-HR',
      'cs-CZ',
      'et-EE',
      'lv-LV',
      'sl-SI',
      'lt-LT',
      'bg-BG',
      //'ru-RU',
      'tr-TR',
      'sr-Latn-RS',
    ],
  },
  {
    key: 'ME',
    value: 'Middle East and Africa',
    languages: ['en-GB,en-US', 'tr-TR', 'ar-EG'],
  },
  {
    key: 'NA',
    value: 'North America',
    languages: ['en-GB,en-US', 'fr-FR,fr-CA', 'es-ES,es-XL,es-SA'],
  },
  {
    key: 'SA',
    value: 'South America',
    languages: ['en-GB,en-US', 'pt-BR', 'es-ES,es-XL,es-SA'],
  },
  {
    key: 'IN',
    value: 'India',
    languages: ['en-GB,en-US'],
  },
];
export const orderBy: InputObject[] = [
  { key: 'Date', value: 'Release date' },
  { key: '', value: 'Relevance' },
  { key: 'Manualtitle', value: 'Product name' },
];

export const defaultOrderBy = 'Date'

export const languages: InputObject[] = [
  { key: '', value: 'All' },
  { key: 'en-GB', value: 'English (GB)' },
  { key: 'en-US', value: 'English (US)' },
  { key: 'en-AU', value: 'English (AU)' },
  { key: 'en-AU,en-US,en-GB', value: 'English' },
  { key: 'en-GB,en-US', value: 'English' },
  { key: 'ar-EG', value: 'Arabic' },
  { key: 'pt-BR', value: 'Brazilian Portuguese' },
  { key: 'bg-BG', value: 'Bulgarian' },
  { key: 'zh-CN', value: 'Chinese' },
  { key: 'hr-HR', value: 'Croatian' },
  { key: 'cs-CZ', value: 'Czech' },
  { key: 'da-DK', value: 'Danish' },
  { key: 'nl-NL', value: 'Dutch' },
  { key: 'et-EE', value: 'Estonian' },
  { key: 'fi-FI', value: 'Finnish' },
  { key: 'fr-FR,fr-CA', value: 'French' },
  { key: 'fr-FR', value: 'French (FR)' },
  { key: 'fr-CA', value: 'French (CA)' },
  { key: 'de-DE', value: 'German' },
  { key: 'el-GR', value: 'Greek' },
  { key: 'hu-HU', value: 'Hungarian' },
  { key: 'it-IT', value: 'Italian' },
  { key: 'ko-KR', value: 'Korean' },
  { key: 'lv-LV', value: 'Latvian' },
  { key: 'lt-LT', value: 'Lithuanian' },
  { key: 'no-NO', value: 'Norwegian' },
  { key: 'pl-PL', value: 'Polish' },
  { key: 'pt-PT', value: 'Portuguese' },
  { key: 'ro-RO', value: 'Romanian' },
  // { key: 'ru-RU', value: 'Russian' },
  { key: 'sk-SK', value: 'Slovak' },
  { key: 'sl-SI', value: 'Slovenian' },
  { key: 'es-ES', value: 'Spanish (ES)' },
  { key: 'es-XL', value: 'Spanish (XL)' },
  { key: 'es-SA', value: 'Spanish (SA)' },
  { key: 'es-ES,es-XL,es-SA', value: 'Spanish' },
  { key: 'sv-SE', value: 'Swedish' },
  { key: 'tr-TR', value: 'Turkish' },
  { key: 'sr-Latn-RS', value: 'Serbian' },
];
export const defaultLanguage = '';

export const types: Array<InputObject & { access?: string }> = [
  { key: '', value: 'All' },
  { key: 'ACC', value: 'Accessories' },
  { key: 'AI', value: 'Assembly instruction' },
  { key: 'DOC', value: 'Declaration of Conformity' },
  { key: 'FT', value: 'Fault tracing guide' },
  { key: 'HB', value: 'Handbook' },
  { key: 'HTML', value: 'HTML Manual'},
  { key: 'ILM', value: 'Installation manual' },
  { key: 'IM', value: 'Instruction manual' },
  { key: 'INT', value: 'Integrator manual' },
  { key: 'PG', value: 'Product guide'},
  { key: 'PM', value: 'Programming manual' },
  { key: 'QG', value: 'Quick guide' },
  { key: 'QSG', value: 'Quick start guide' },
  { key: 'SI', value: 'Safety instruction' },
  { key: 'SM', value: 'Service manual' },
  { key: 'SU', value: 'Software upgrade instruction' },
  { key: 'SPL', value: 'Spare parts list (Replacement parts)' },
  { key: 'TI', value: 'Troubleshooting instruction' },
  { key: 'WP', value: 'Wear parts' },
  { key: 'WD', value: 'Wiring diagram' },
];
export const defaultType = '';

export type OptionGroup = {
  title: string;
  items: InputObject[];
};

export type SelectItem = InputObject | OptionGroup;

export function isOptionGroup(item: SelectItem): item is OptionGroup {
  return (item as OptionGroup).title !== undefined;
}

export const categories: SelectItem[] = [
  { key: '', value: 'All' },
  {
    title: 'Arc gouging (CAC-A) and exothermic cutting',
    items: [
      { key: 'arx-acc', value: 'Accessories' },
      { key: 'arx-gou', value: 'Carbon arc gouging' },
      { key: 'arx-cut', value: 'Exothermic cutting' },
      { key: 'arx-wcw', value: 'Underwater cutting/welding' },
    ],
  },
  {
    title: 'Arc welding equipment',
    items: [
      { key: 'arc-acc', value: 'Accessories' },
      { key: 'arc-syn', value: 'Advanced synergic multi-process equipment' },
      { key: 'arc-cont', value: 'Control (panel/unit)' },
      { key: 'arc-eng', value: 'Engine driven welding units' },
      { key: 'arc-mig', value: 'MIG equipment (CV)' },
      { key: 'arc-mma', value: 'MMA equipment (CC)' },
      { key: 'arc-mpe', value: 'Multi-process equipment (CV/CC)' },
      { key: 'arc-ps', value: 'Power source (MMA/TIG/MIG/multiprocess)' },
      { key: 'arc-tig', value: 'TIG equipment (CC)' },
      { key: 'arc-tmig', value: 'Torch MIG' },
      { key: 'arc-ttig', value: 'Torch TIG' },
      { key: 'arc-wf', value: 'Wire feeder' },
    ],
  },
  {
    title: 'Digital solution',
    items: [
      { key: 'eds-acc', value: 'Accessories' },
	  ],
  },
  {
    title: 'Gas equipment',
    items: [
      { key: 'gas-acc', value: 'Accessories' },
      { key: 'gas-brso', value: 'Brazing and soldering' },
      { key: 'gas-reg', value: 'Gas regulators' },
      { key: 'gas-ind', value: 'Industrial' },
      { key: 'gas-tor', value: 'Torches and components' },
    ],
  },
  {
    title: 'Plasma',
    items: [
      { key: 'pls-acc', value: 'Accessories' },
      { key: 'pls-cuac', value: 'Plasma cut accessories' },
      { key: 'pls-cuto', value: 'Plasma cut torches' },
      { key: 'pls-cuun', value: 'Plasma cut units' },
      { key: 'pls-weld', value: 'Plasma welding' },
    ],
  },

  {
    title: 'PPE & accessories',
    items: [
      { key: 'ppe-acc', value: 'Accessories' },
      { key: 'ppe-ppe', value: 'PPE' },
    ],
  },
  {
    title: 'Welding automation and robotics',
    items: [
      { key: 'wel-acc', value: 'Accessories' },
      { key: 'wel-car', value: 'Carrier manipulators & handling equipment' },
      { key: 'wel-cp', value: 'Control panel (aut/mechtig/robot)' },
      { key: 'wel-flux', value: 'Flux recovery' },
      { key: 'wel-fsw', value: 'Friction stir welding (FSW)' },
      { key: 'wel-gmaw', value: 'Gas metal arc welding (GMAW)' },
      { key: 'wel-gtaw', value: 'Gas tungsten arc welding (GTAW)' },
      { key: 'wel-ind', value: 'Industries' },
      { key: 'wel-jts', value: 'Joint tracking/slides' },
      { key: 'wel-saw', value: 'Laser beam Submerged Arc welding (SAW)' },
      { key: 'wel-mt', value: 'Mechtig' },
      { key: 'wel-ps', value: 'Power source (aut)' },
      { key: 'wel-sol', value: 'Process solutions' },
      { key: 'wel-ref', value: 'References' },
      { key: 'wel-tor', value: 'Torches (robot, etc)' },
      { key: 'wel-tra', value: 'Tractor' },
      { key: 'wel-wh', value: 'Welding head' },
      { key: 'wel-wf', value: 'Wire feeders (feed unit aut/robot)' },
    ],
  },
];

export const itemsPerPage: number = 10;

// Helper to get item from array above by key
export const valueForKey = (key: string, items: InputObject[]) => {
  const item = items.find((item) => item.key === key);

  if (!item) {
    console.error(`Could not find value for key '${key}' in object`, items);
    return null;
  }

  return item.value;
};

// https://davidwalsh.name/query-string-javascript
export const getUrlParameter = (name: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const urlParameterIsTrue = (name: string) => {
  const parameter = getUrlParameter(name);
  return parameter === '1';
};
