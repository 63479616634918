import { makeAutoObservable } from 'mobx';
import { createModelSchema, alias, list, custom, primitive } from 'serializr';
import { regions, types, languages, valueForKey } from '../settings';

export default class Pdf {
  id = '';
  productid = [];
  name = '';
  comments = '';
  url = '';
  types = [];
  regions = [];
  languages = [];
  accesstype = [];
  gins = [];
  serialNo = '';
  documentNumber = '';
  releasedate = '';
  certificate = '';
  softwareNumber = '';
  global = false;
  isSite = false;
  objectType = '';
  manifestId = '';
  objectId = '';

  constructor() {
    makeAutoObservable(this);
  }

  get details(): string {
    let parts = [];
    if (this.gins.length > 0) {
      parts.push(`Article No: ${this.gins.join(', ')}`);
    }
    if (this.serialNo) {
      parts.push(
        `<span class="bold">Serial number: ${this.serialNo}</span>`
      );
    }
    if (this.documentNumber) {
      parts.push(`Document number: ${this.documentNumber}`);
    }
    if (this.softwareNumber) {
      parts.push(`Software number: ${this.softwareNumber}`);
    }
    if (this.releasedate) {
      parts.push(`Release date: ${this.releasedate}`);
    }
    if (this.certificate) {
      parts.push(`Certificate: ${this.certificate}`);
    }
    return parts.join(' - ');
  }
}

createModelSchema(Pdf, {
  id: true,
  productid: alias('productid', list(primitive())),
  name: alias('manualtitle', true),
  comments: alias('comments', true),
  releasedate: alias('releasedate', true),
  certificate: true,
  global: custom(
    () => { },
    (value) => value === 'yes'
  ),
  objectType: alias('objecttype', true),
  manifestId: alias('manifestId', true),
  isSite: alias(
    'objecttype',
    custom(
      () => { },
      (key) => key === 'html'
    )
  ),
  url: alias('filelink', true),
  types: alias(
    'manualtype',
    list(
      custom(
        () => { },
        (key) => valueForKey(key, types)
      )
    )
  ),
  regions: alias(
    'marketregion',
    list(
      custom(
        () => { },
        (key) => valueForKey(key, [...regions, { key: 'ALL', value: 'All' }])
      )
    )
  ),
  languages: alias(
    'pdflanguage',
    list(
      custom(
        () => { },
        (key) => valueForKey(key, languages)
      )
    )
  ),
  accessTypes: alias('accesstype', list(primitive())),
  gins: alias('productgin', list(primitive())),
  serialNo: alias('serialNo', true),
  documentNumber: alias('documentno', true),
  softwareNumber: alias('softwareno', true),
});
