import React from 'react';
import Modal from './Modal';
import styled from 'styled-components';

const Title = styled.h2`
  font-size: 28px;
  margin: 0 0 16px 0;
`;

const ExplanationTitle = styled.h3`
  margin: 0 0 6px 0;
`;

const Explanation = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

const ExplanationRow = styled.p`
  margin: 0px;
`;

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const InfoModal: React.FC<Props> = ({ isVisible, onClose }) => (
  <Modal isVisible={isVisible} onClose={onClose}>
    <Title>OPTIMIZE YOUR SEARCH RESULTS</Title>
    <Explanation>
      Search operators can be used to narrow the focus of the manual search
      engine and make your search results more precise. Example use of search
      operators for the fictive product <em>"Weldproduct 500"</em>:
    </Explanation>
    <ExplanationTitle>Asterisk (*) - Wildcard</ExplanationTitle>
    <Explanation>
      <ExplanationRow>Example:</ExplanationRow>
      <ExplanationRow>Search for <em>weldproduc</em> - 0 hits</ExplanationRow>
      <ExplanationRow>Search for <em>weldproduc*</em> - Hits on weldproduct</ExplanationRow>
      <ExplanationRow>Search for <em>500</em> - Hits on 500</ExplanationRow>
      <ExplanationRow>Search for  <em>500*</em> - Hits of 5000, 50000 or 5000000000</ExplanationRow>
    </Explanation>
    <ExplanationTitle>Quotes ("") - Must be in this order</ExplanationTitle>
    <Explanation>
      <ExplanationRow>Example:</ExplanationRow>
      <ExplanationRow>Search for <em>"weldproduct 500"</em> - To get a hit, weldproduct 500 must be present and in that order, "500 weldproduct" is not a valid hit.</ExplanationRow>
    </Explanation>
    <ExplanationTitle>Plus (+) - The word must be present</ExplanationTitle>
    <Explanation>
      <ExplanationRow>Example:</ExplanationRow>
      <ExplanationRow>Search for <em>weldproduct +500</em> - Weldproduct and 500 must exist but must not be in exactly that order.</ExplanationRow>
    </Explanation>
    <ExplanationTitle>Minus (-) - The word must not exist</ExplanationTitle>
    <Explanation>
      <ExplanationRow>Example:</ExplanationRow>
      <ExplanationRow>Search for <em>weldproduct -600</em> - Search result contains weldproduct but not 600.</ExplanationRow>
    </Explanation>
    <ExplanationTitle>Question mark (?) - One-letter wildcard</ExplanationTitle>
    <Explanation>
      <ExplanationRow>Example:</ExplanationRow>
      <ExplanationRow>Search for <em>weld?roduct</em> - Valid hits could be weldproduct, weldcroduct, weldsroduct etc. if available.</ExplanationRow>
    </Explanation>
  </Modal>

);

export default InfoModal;
