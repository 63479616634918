import { runInAction, makeAutoObservable } from 'mobx';
import { deserialize } from 'serializr';
import axios from 'axios';
import Pdf from '../models/Pdf';
import { itemsPerPage } from '../settings';
import { getToken } from 'src/services/api';
import { constants } from '../constants';

import jwt_decode from 'jwt-decode';
import { authenticate } from 'src/hooks/useAuth';
export default class PdfStore {
  items: Pdf[] = [];
  page = 1;
  pages = 1;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (
    searchterm: string,
    page: number = 1,
    filter: string,
    orderBy: string
  ) => {
    let searchmode = 'any';
    if (
      searchterm.indexOf('*') > -1 ||
      searchterm.indexOf('+') > -1 ||
      searchterm.indexOf('-') > -1 ||
      searchterm.indexOf('?') > -1
    )
      searchmode = 'all';

    // We are testing searchmode all for everything currently, previous code above
    // let searchmode = 'all';

    const token = getToken(constants.tokenVersion);
    if (!token) {
      await authenticate();
    } else {
      var decodedToken = jwt_decode<Esab.JwtToken>(token);
      if (new Date().getTime() / 1000 >= decodedToken.exp) {
        await authenticate();
      }
    }

    console.log("filter = ", filter);
    console.log("term =", searchterm);
    console.log("searchmode = ", searchmode);
    console.log("orderby", orderBy);
    console.log("top = ", itemsPerPage);
    console.log("skip = ", (page * itemsPerPage) - 10);
    console.log("page = ", page);

    const json = await axios.get(
      `${process.env.REACT_APP_AZURE_SEARCH_URL}/indexes/${
        process.env.REACT_APP_PRODUCT_INDEX
      }/docs?api-version=2021-04-30-Preview&search=${searchterm}${
        filter === '' ? `` : `&$filter=${filter}`
      }&queryType=full&size=10&searchMode=${searchmode}${
        orderBy === '' ? '' : `&$orderby=${orderBy}&$count=true&$skip=${(page * itemsPerPage) - 10}&$top=${itemsPerPage}&api-key=${process.env.REACT_APP_AZURE_API_KEY}`
      }`,
      {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_AZURE_API_KEY
        },
      }
    );

      console.log(json.data["@odata.count"]);

    runInAction(() => {
      this.items = json.data.value.map((item: any) => {
        return deserialize(Pdf, item);
      });

      this.page = page;
      this.pages = Math.max(1, Math.ceil(json.data["@odata.count"] / itemsPerPage));
    });
  };
}
