import React, { FormEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import InputSelect from './InputSelect';
import InputText from './InputText';
import { ButtonWrapper, Button, ClearButton } from './styled/Input';
import { regions, categories, orderBy } from '../settings';
import { useStores } from '../context/StoreContext';
import InputCheckbox from './InputCheckbox';
import { ReactComponent as InfoIcon } from '../assets/images/info.svg';
import styled from 'styled-components';
import InfoModal from './InfoModal';

const InfoButton = styled.button`
  background: transparent;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  padding: 0;
`;

const SearchForm = observer(() => {
  const [showModal, setShowModal] = useState(false);
  const { searchStore, uiStore } = useStores();
  const { form } = searchStore;
  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    if (searchStore.shouldPerformInitialSearch) {
      searchStore.performSearch();
    }
  }, [searchStore]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchStore.performSearch();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputText
          title="Product name / Article NO / DOC NO"
          name="text"
          value={form.text}
          onChange={(e) => form.setText(e.target.value)}
        />
        <InputSelect
          title="Document type"
          name="type"
          values={searchStore.availableTypes}
          value={form.type}
          onChange={(e) => form.setType(e.target.value)}
        />
        <InputSelect
          title="Category"
          name="category"
          values={categories}
          value={form.category}
          onChange={(e) => form.setCategory(e.target.value)}
        />
        {uiStore.showRegionDropdown && (
          <InputSelect
            title="Region"
            name="region"
            values={regions}
            value={form.region}
            onChange={(e) => form.setRegion(e.target.value)}
          >
            <InputCheckbox
              title="Strict region checking"
              name="strictRegionChecking"
              value={form.strictRegionChecking}
              onChange={(e) => form.setStrictRegionChecking(e.target.checked)}
            />
          </InputSelect>
        )}
        <InputSelect
          title="Language"
          name="language"
          values={searchStore.availableLanguages}
          value={form.language}
          onChange={(e) => form.setLanguage(e.target.value)}
          stripValueRegex={/ \(.*$/}
        />
        <InputSelect
          title="Sort by"
          name="sorting"
          values={orderBy}
          value={form.orderBy}
          onChange={(e) => form.setOrderby(e.target.value)}
          stripValueRegex={/ \(.*$/}
        />
        <ButtonWrapper>
          <ClearButton
            disabled={!searchStore.formIsDirty}
            type="button"
            onClick={searchStore.clearForm}
          >
            Clear
          </ClearButton>
          <Button>Search</Button>
          <InfoButton
            onClick={(e) => {
              e.preventDefault();
              handleModalToggle();
            }}
          >
            <InfoIcon />
          </InfoButton>
        </ButtonWrapper>
      </form>
      <InfoModal isVisible={showModal} onClose={handleModalToggle} />
    </>
  );
});

export default SearchForm;
