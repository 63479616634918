import styled from 'styled-components';

export const Label = styled.label`
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  position: relative;
  vertical-align: top;

  @media screen and (max-width: 600px) {
    display: block;
    margin-right: 0;
  }
`;

export const CheckboxLabel = styled(Label)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const LabelText = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
  color: #767476;
  text-transform: uppercase;
`;

export const CheckboxLabelText = styled(LabelText)`
  margin-left: 20px;
`;

export const TextInput = styled.input`
  font-size: 16px;
  padding: 8px;
  height: 38px;
  border: 1px solid #D1D1D1;
  background: #FFF;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const Select = styled.div`
  position: relative;
  display: block;
  width: 100%;
  &:after {
    content: '\\f0d7';
    font-family: FontAwesome;
    font-size: 14px;
    color: #1A171B;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -6px;
    z-index: 1;
    text-align: center;
    width: 20px;
    height: 100%;
    pointer-events: none;
    box-sizing: border-box;
  }
  select::-ms-expand {
    display: none;
  }
`;

export const SelectInput = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 38px;
  width: 100%;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 8px 28px 8px 8px;
  background: #FFF;
`;

export const Checkbox = styled.input`
  float: left;
  margin: 0;
`;

export const Button = styled.button`
  height: 38px;
  font-size: 16px;
  font-weight: bold;
  background-color: #FFF;
  color: #333;
  border: 0;
  box-sizing: content-box;
  padding: 0px 20px;
  outline: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: #E8E8E8;
  }
  &:active {
    background-color: #9A999B;
    color: #E8E8E8
  }
  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    background-color: #E8E8E8;
    color: #BAB9BB;
    `}
`;

export const ClearButton = styled.button`
  height: 38px;
  font-size: 16px;
  background-color: #FBE400;
  color: #1A171B;
  border: 1px solid #484549;
  border-radius: 6px;
  margin-right: 15px;
  box-sizing: content-box;
  padding: 0px 20px;
  cursor: pointer;
  &:hover {
  }
  &:active {
  }
  ${(p) =>
    p.disabled &&
    `
    color: #9A999B;
    border: 1px solid #9A999B;
    pointer-events: none;
    `}
`;

export const ButtonWrapper = styled.div`
  float: right;
  margin-top: 16px;
  margin-right: 43px;

  @media screen and (max-width: 600px) {
    margin-right: unset;
  }
`;