import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Pdf from '../models/Pdf';
import { ReactComponent as SiteIcon } from '../assets/images/site.svg';
import { ReactComponent as PdfIcon } from '../assets/images/pdf.svg';
import { getUrlParameter } from '../settings';

type Props = {
  pdf: Pdf;
};

const PdfItem = ({ pdf }: Props) => {

  let link: string;

  const getLink = () => {
    if (pdf.isSite)
    {
      link = `${process.env.REACT_APP_MANUALSITE_URL}/${pdf.manifestId}${getUrlParameter('user') ? `?user=${getUrlParameter('user')}` : ''}`;
    }
    else {
      link = pdf.url;
    }

    return link;
  }

  return <Container>
    <ColumnWrapper>
      <Column size="large">
        <Title dangerouslySetInnerHTML={{ __html: pdf.name }}></Title>
        <Description>{pdf.comments}</Description>
        <Type>{pdf.types.join(', ')}</Type>
      </Column>
      <Column>
        <Item>
          <ItemTitle>Region</ItemTitle>
          <div>{pdf.regions.join(', ')}</div>
        </Item>
        <Item>
          <ItemTitle>Language</ItemTitle>
          <div>{pdf.languages.join(', ')}</div>
        </Item>
      </Column>
      <Column>
        <Link href={getLink()} target="_blank" className='pdf-link'>
          <ButtonIcon>{pdf.isSite ? <SiteIcon /> : <PdfIcon />}</ButtonIcon>
          OPEN {pdf.isSite ? 'WEBSITE' : 'PDF'}
        </Link>
      </Column>
    </ColumnWrapper>
    <Details dangerouslySetInnerHTML={{ __html: pdf.details }}></Details>
  </Container>
}

const Title = styled.h2`
  font-size: 28px;
  margin: 0 0 2px 0;
`;

const Description = styled.div`
  font-style: italic;
  font-weight: 100;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Type = styled.div`
  font-size: 16px;
  color: #888;
  @media screen and (max-width: 600px) {
   margin-bottom: 8px;
  }
`;

const Details = styled.div`
  border-top: 1px solid #eee;
  color: #333;
  padding: 5px 20px;
  font-size: 11px;
  .bold {
    font-weight: bold;
  }
`;

const Container = styled.div`
  margin-bottom: 10px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  &:hover {
    Button {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.75);
    }
  }
`;

const ColumnWrapper = styled.div`
  padding: 20px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

const Column = styled.div<{ size?: string }>`
  float: left;
  width: ${(props) => (props.size === 'large' ? '40%' : '30%')};
  box-sizing: border-box;
  padding-right: 20px;
  &:last-child {
    padding-right: 0;
    text-align: right;
  }
  @media screen and (max-width: 600px) {
    float: none;
    width: 100%;
  }
`;

const Item = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemTitle = styled.h4`
  margin: 0 0 2px 0;
  font-size: 12px;
`;

const Link = styled.a`
  height: 38px;
  font-size: 16px;
  background-color: #FFF;
  color: #333;
  border: 0;
  padding: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: #E8E8E8;
  }
  &:active {
    background-color: #9A999B;
    color: #E8E8E8;
    svg path,
    svg rect,
    svg line,
    svg ellipse,
    svg circle {
      &:not[fill='none'] {
        fill: #E8E8E8;
      }
      stroke: #E8E8E8;
    }
`;

const ButtonIcon = styled.span`
  vertical-align: middle;
  margin-right: 10px;
`;

export default observer(PdfItem);
