import * as React from 'react';
import { useMutation } from 'react-query';
import api, { getToken, setToken } from '../services/api';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { constants } from '../constants';

type AuthRequestData = {
  username: string;
  password: string;
  customer: string;
  site: string;
  environment: string;
};

type ErrorResponse = {
  status: number;
};

type ConfigResponse = {
  status: number;
  data: Esab.ConfigObject;
};

export function useSetConfig(): any {
  return useMutation<ConfigResponse>(() =>
    api
      .get(`/auth/v1/jwt/config`, {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          Authorization: `Bearer ${getToken(constants.tokenVersion)}`,
        },
      })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
  );
}

export function useConfig(): Esab.ConfigObject | undefined {
  if (sessionStorage.getItem(constants.configVersion)) {
    return JSON.parse(sessionStorage.getItem(constants.configVersion));
  }

  return undefined;
}
export function useValidate() {
  return useMutation<
    {
      status: number;
    },
    ErrorResponse
  >(() =>
    api
      .post(
        `/auth/v1/jwt/authorize`,
        {
          customer: process.env.REACT_APP_CUSTOMER,
          method: 'Get',
          site: process.env.REACT_APP_SITE,
          environment: process.env.REACT_APP_ENVIRONMENT,
        },
        {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
            Authorization: `Bearer ${getToken('token')}`,
          },
        }
      )
      .then((response) => {
        return { status: response?.status };
      })
      .catch((error) => {
        return error;
      })
  );
}

export function useAuthenticate() {
  return useMutation<
    { status: number; data: { token: string; refreshToken: string } },
    ErrorResponse
  >(() =>
    api
      .post(
        `/auth/v1/jwt/authenticate`,
        {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD,
          customer: process.env.REACT_APP_CUSTOMER,
          site: process.env.REACT_APP_SITE,
          environment: process.env.REACT_APP_ENVIRONMENT,
        },
        {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          },
        }
      )
      .then((response) => {
        return { status: response.status, data: response.data };
      })
  );
}

export async function authenticate() {
  const jwt = await axios.post(
    `${process.env.REACT_APP_AUTH_API_URL}/auth/v1/jwt/authenticate` ?? '',
    {
      username: process.env.REACT_APP_USERNAME,
      password: process.env.REACT_APP_PASSWORD,
      customer: process.env.REACT_APP_CUSTOMER,
      site: process.env.REACT_APP_SITE,
      environment: process.env.REACT_APP_ENVIRONMENT,
    },
    {
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
      },
    }
  );

  setToken(constants.tokenVersion, jwt.data.token);
}
