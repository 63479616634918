import React from 'react';
import styled from 'styled-components';
import { createUltimatePagination } from 'react-ultimate-pagination';

export default createUltimatePagination({
  itemTypeToComponent: {
    PAGE: (props) => (
      <Item active={props.isActive} onClick={props.onClick}>
        {props.value}
      </Item>
    ),
    ELLIPSIS: (props) => <Item onClick={props.onClick}>...</Item>,
    FIRST_PAGE_LINK: (props) => (
      <Item disabled={props.isActive} onClick={props.onClick}>
        First
      </Item>
    ),
    PREVIOUS_PAGE_LINK: (props) => (
      <Item disabled={props.isActive} onClick={props.onClick}>
        Previous
      </Item>
    ),
    NEXT_PAGE_LINK: (props) => (
      <Item disabled={props.isActive} onClick={props.onClick}>
        Next
      </Item>
    ),
    LAST_PAGE_LINK: (props) => (
      <Item disabled={props.isActive} onClick={props.onClick}>
        Last
      </Item>
    ),
  },
  WrapperComponent: (props) => (
    <Wrapper className="pagination">{props.children}</Wrapper>
  ),
});

const Wrapper = styled.div`
  text-align: center;
  border-radius: 5px;
`;

const Item = styled.div<{ active?: boolean; disabled?: boolean }>`
  background: ${(props) => (props.active ? '#eee' : '#fff')};
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  border-bottom: 0;
  border-left: none;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};

  &:first-child {
    border-left: 1px solid #ddd;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background: #e3e3e3;
  }
`;
