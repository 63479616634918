import { autorun, makeAutoObservable, runInAction } from 'mobx';
import { deserialize, serialize } from 'serializr';
import { isEqual } from 'lodash';
import Form from '../models/Form';
import {
  getUrlParameter,
  defaultLanguage,
  defaultType,
  regions,
  languages,
  types,
  defaultOrderBy,
} from '../settings';
import PdfStore from './PdfStore';
export default class SearchStore {
  pdfStore: PdfStore;
  user = '';
  form: Form;
  hasPerformedSearch = false;
  searching = false;

  constructor(pdfStore: PdfStore) {
    this.pdfStore = pdfStore;
    makeAutoObservable(this);
    this.user = getUrlParameter('user') || 'public';
    this.form = deserialize(Form, this.defaultValues);
    // If availableLanguages changes, update language as well
    // to make sure it has a valid value
    autorun(() => {
      const invalidLanguage = !this.availableLanguages
        .map((language) => language.key)
        .includes(this.form.language);

      if (invalidLanguage) {
        runInAction(() => {
          this.form.setLanguage('');
        });
      }
    });
  }

  clearForm = () => {
    // Set initial values from query parameters
    this.form = deserialize(Form, this.defaultValues);
  };

  get defaultValues() {
    return {
      text: getUrlParameter('text'),
      type: getUrlParameter('type') || defaultType,
      category: getUrlParameter('category'),
      region: getUrlParameter('region'),
      language: getUrlParameter('language') || defaultLanguage,
      orderBy: getUrlParameter('orderby') || defaultOrderBy,
      strictRegionChecking: false,
    };
  }

  get formIsDirty() {
    return !isEqual(serialize(this.form), this.defaultValues);
  }

  get filter(): string {
    let filter = [];

    const { type, region, category, language, strictRegionChecking } =
      this.form.trimmedValues;
    if (type.length) {
      if (type.toLowerCase() == 'html')
        {filter.push(`objecttype eq '${type.toLowerCase()}'`);}
      else
        {filter.push(`manualtype/any(m: m eq '${type}') and objecttype ne 'html'`);}
    }
    //Pdflanguage
    if (region.length) {
      if (strictRegionChecking) {
        filter.push(`marketregion/any(mrs: mrs eq '${region}')`);
      } else {
        filter.push(
          `(marketregion/any(mr: mr eq '${region}' or mr eq 'ALL') or global eq 'yes')`
        );
      }
    }
    if (language.length) {
      const equality = language
        .split(',')
        .map((language) => `pl eq '${language}'`)
        .join(' or ');
      filter.push(`(pdflanguage/any(pl: ${equality}) or global eq 'yes')`);
    }

    if (category.length) {
      filter.push(`productsubcategory eq '${category}'`);
    }
    const user = this.user.trim();
    if (user.length) {
      filter.push(`accesstype/any(at: at eq '${user}')`);
    }

    return filter.join(' and ');
  }
  get OrderBy(): string {
    const { orderBy } = this.form.trimmedValues;
    let orderByFilter = '';
    switch (orderBy) {
      case 'Date':
        orderByFilter = 'date desc';
        break;
      case 'Manualtitle':
        orderByFilter = 'manualtitle asc';
        break;
      default:
        orderByFilter = '';
        break;
    }
    return orderByFilter;
  }

  performSearch = (page: number = 1) => {
    let trimmedText = this.form.trimmedValues.text;

    //escape unsafe and reserved Lucene characters except *"+-?
    //https://docs.microsoft.com/en-us/azure/search/query-lucene-syntax#bkmk_syntax
    const text = trimmedText.replace(
      /([\!\=\<\>\&\|\(\)\[\]\{\}\^\~\:\\/])/g,
      '\\$1'
    );

    const nospacesText = text.replace(/ /g, '');
    const searchterm = text
      ? `manualtitle: (${text}) OR nickname: (${nospacesText}) OR familyname: (${nospacesText}) OR comments: (${text}) OR productid: (${nospacesText}) OR productgin: (${nospacesText}) OR documentno: (${nospacesText})`
      : '*';

    const filter = this.filter;
    const orderBy = this.OrderBy;

    this.hasPerformedSearch = true;
    this.setSearching(true);

    var url = new URL(window.location.href);

    this.setQueryParameter(url, 'text', trimmedText);
    this.setQueryParameter(url, 'type', this.form.trimmedValues.type);
    this.setQueryParameter(url, 'category', this.form.trimmedValues.category);
    this.setQueryParameter(url, 'region', this.form.trimmedValues.region);
    this.setQueryParameter(url, 'language', this.form.trimmedValues.language);
    this.setQueryParameter(url, 'orderby', this.form.trimmedValues.orderBy);

    window.history.pushState(null, document.title, url.href);

    this.pdfStore
      .fetch(searchterm, page, filter, orderBy)
      .then(() => {
        this.setSearching(false);
      })
      .catch((e) => {
        this.setSearching(false);
      });
  };

  setQueryParameter = (url: URL, key: string, value: string) => {
    if (value) url.searchParams.set(key, value);
    else url.searchParams.delete(key);
  };

  setSearching = (searching: boolean) => {
    this.searching = searching;
  };

  get availableLanguages(): typeof languages {
    const region = regions.find((region) => region.key === this.form.region);
    if (!region) {
      return languages;
    }
    return languages.filter(
      (language, index) =>
        index === 0 || region.languages.includes(language.key)
    );
  }

  get availableTypes(): typeof types {
    return types.filter((type) => !type.access || type.access === this.user);
  }

  get shouldPerformInitialSearch(): boolean {
    const { text, type, region, category, language } = this.form.trimmedValues;
    return (
      !this.hasPerformedSearch &&
      (text.length > 0 ||
        type.length > 0 ||
        category.length > 0 ||
        region.length > 0 ||
        language.length > 0)
    );
  }
}
