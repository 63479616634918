import { makeAutoObservable } from 'mobx';
import { createModelSchema, primitive } from 'serializr';

export default class Form {
  text = '';
  type = '';
  category = '';
  region = '';
  language = '';
  strictRegionChecking = false;
  orderBy = '';

  constructor() {
    makeAutoObservable(this);
  }

  setText = (text: string) => {
    this.text = text;
  };

  setType = (type: string) => {
    this.type = type;
  };

  setCategory = (category: string) => {
    this.category = category;
  };

  setRegion = (region: string) => {
    this.region = region;
  };

  setLanguage = (language: string) => {
    this.language = language;
  };

  setOrderby = (orderBy: string) => {
    this.orderBy = orderBy
  }
  setStrictRegionChecking = (strictRegionChecking: boolean) => {
    this.strictRegionChecking = strictRegionChecking;
  };

  get trimmedValues() {
    return {
      text: this.text.trim(),
      type: this.type.trim(),
      category: this.category.trim(),
      region: this.region.trim(),
      language: this.language.trim(),
      orderBy: this.orderBy.trim(),
      strictRegionChecking: this.strictRegionChecking,
    };
  }
}

createModelSchema(Form, {
  text: primitive(),
  type: primitive(),
  category: primitive(),
  region: primitive(),
  language: primitive(),
  strictRegionChecking: primitive(),
  orderBy: primitive()
});
