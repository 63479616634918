import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import PdfList from './PdfList';
import Footer from './Footer';
import { useAuthenticate, useConfig, useSetConfig } from '../hooks/useAuth';
import { constants } from '../constants';
import { setToken } from 'src/services/api';

const App = () => {
  const { mutateAsync } = useAuthenticate();
  const configMutation = useSetConfig();
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const setup = async () => {
      const authenticated = await mutateAsync();

      if (authenticated.status === 200) {
        setToken(constants.tokenVersion, authenticated.data.token);
      }
      const config = await configMutation.mutateAsync();

      if (config.status === 200 && config.data) {
        setToken(constants.configVersion, JSON.stringify(config.data));
        setLoaded(true);
      }
    };

    setup();
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <Wrapper>
      <PageRow>
        <Header />
      </PageRow>
      <PageRow expanded>
        <PdfList />
      </PageRow>
      <PageRow>
        <Footer />
      </PageRow>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: table;
`;

const PageRow = styled.div<{ expanded?: boolean }>`
  display: table-row;
  height: ${(props) => (props.expanded ? '100%' : 'auto')};
`;

export default App;
