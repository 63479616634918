import React, { ChangeEvent } from 'react';
import { CheckboxLabel, CheckboxLabelText, Checkbox } from './styled/Input';

type Props = {
  title: string;
  name: string;
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputCheckbox = ({ title, name, value, onChange }: Props) => (
  <CheckboxLabel>
    <Checkbox
      type="checkbox"
      name={name}
      value={value ? 'on' : 'off'}
      onChange={onChange}
      checked={value}
    />
    <CheckboxLabelText>{title}</CheckboxLabelText>
  </CheckboxLabel>
);
export default InputCheckbox;
