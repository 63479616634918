import React, { ChangeEvent } from 'react';
import { isOptionGroup, SelectItem } from '../settings';
import { Label, LabelText, Select, SelectInput } from './styled/Input';

type Option = {
  key: string;
  value: string;
};

type Props = {
  title: string;
  name: string;
  values: SelectItem[];
  value: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  stripValueRegex?: RegExp | string;
  children?: React.ReactNode;
};

const InputSelect = ({
  title,
  name,
  values,
  value,
  onChange,
  stripValueRegex,
  children,
}: Props) => {
  const option = (item: Option) => (
    <option key={item.key} value={item.key}>
      {stripValueRegex ? item.value.replace(stripValueRegex, '') : item.value}
    </option>
  );

  return (
    <Label>
      <LabelText>{title}</LabelText>
      <Select>
        <SelectInput name={name} onChange={onChange} value={value}>
          {values.map((item) =>
            isOptionGroup(item) ? (
              <optgroup key={item.title} label={item.title}>
                {item.items?.map((subitem) => option(subitem))}
              </optgroup>
            ) : (
              option(item)
            )
          )}
        </SelectInput>
      </Select>
      {children}
    </Label>
  );
};

export default InputSelect;
