import { makeAutoObservable } from 'mobx';
import { getUrlParameter, urlParameterIsTrue } from '../settings';

export default class UIStore {
  showRegionDropdown = false;
  showLogo = false;

  constructor() {
    makeAutoObservable(this);
    this.showRegionDropdown = !getUrlParameter('region');
    this.showLogo = !urlParameterIsTrue('embedded');
  }
}
