import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import MDSpinner from 'react-md-spinner';
import PdfItem from './PdfItem';
import Pager from './Pager';
import Content from './styled/Content';
import { useStores } from '../context/StoreContext';
import { ReactComponent as InstructionsImage } from '../assets/images/instructions.svg';

const PdfList = () => {
  const { searchStore, pdfStore } = useStores();

  return (
    <Container>
      <Content>
        {!searchStore.hasPerformedSearch ? (
          <Instructions>
            <InstructionsTitle>
              Enter your search criteria above to locate the documents
            </InstructionsTitle>
            <InstructionsSubTitle>
              Service information and similar documents are available only from
              the
              <InstructionsSubTitleLink href="https://www.esab.com/login">
                ESAB Partner Portal
              </InstructionsSubTitleLink>
            </InstructionsSubTitle>

            <InstructionsImage />
            <InstructionsText>
              <p>
                Search using the product name or the article number specified on
                the rating plate. The article number is usually the same as the
                ordering number mentioned in the manual. If the document is
                valid only for a specific serial number or software version,
                that will be shown in the search result together with some other
                information.
              </p>
              <p>
                Use the filters for language and document type to narrow the
                search result.
              </p>
            </InstructionsText>
          </Instructions>
        ) : !searchStore.searching && !pdfStore.items.length ? (
          <Instructions>No results found</Instructions>
        ) : (
          pdfStore.items.map((pdf, index) => (
            <div key={`${pdf.objectId}-${index}`}>
              <PdfItem pdf={pdf} />
            </div>
          ))
        )}
        {pdfStore.pages > 1 && (
          <Pager
            totalPages={pdfStore.pages}
            currentPage={pdfStore.page}
            boundaryPagesRange={3}
            onChange={searchStore.performSearch}
          />
        )}
      </Content>
      {searchStore.searching && (
        <SpinContainer>
          <SpinContent>
            <MDSpinner singleColor="#fee509" size={100} />
          </SpinContent>
        </SpinContainer>
      )}
    </Container>
  );
};
const Container = styled.div`
  padding-top: 20px;
`;

const Instructions = styled.div`
  color: #aaa;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 820px;
  margin: 32px auto 0 auto;
`;

const InstructionsTitle = styled.div`
  margin-bottom: 20px;
  font-size: 32px;
  text-align: center;
`;

const InstructionsSubTitle = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
`;

const InstructionsSubTitleLink = styled.a`
  cursor: pointer;
  margin-left: 4px;
  color: #000;
`;

const InstructionsText = styled.div`
  max-width: 800px;
  font-size: 14px;
`;

const SpinContainer = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

const SpinContent = styled.div`
  position: absolute;
  top: 300px;
  left: 50%;
  margin-left: -50px;
`;

export default observer(PdfList);
