import React, { ChangeEvent } from 'react';
import { Label, LabelText, TextInput } from './styled/Input';

type Props = {
  title: string;
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputText = ({ title, name, value, onChange }: Props) => (
  <Label>
    <LabelText>{title}</LabelText>
    <TextInput type="text" name={name} value={value} onChange={onChange} />
  </Label>
);
export default InputText;
