import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.css';
import { SearchStore, UIStore, PdfStore } from './stores';
import { StoreProvider } from './context/StoreContext';
import { QueryClientProvider, QueryClient } from 'react-query';

configure({ useProxies: 'ifavailable' });

const uiStore = new UIStore();
const pdfStore = new PdfStore();
const searchStore = new SearchStore(pdfStore);
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StoreProvider value={{ uiStore, pdfStore, searchStore }}>
      <App />
    </StoreProvider>
  </QueryClientProvider>,

  document.getElementById('root')
);
