import React from 'react';
import styled from 'styled-components';
import Content from './styled/Content';

const Footer = () => (
  <FooterContainer>
    <Content>
      If you have any comments, suggestions or questions about this site,
      contact{' '}
      <Link href="mailto:documentation@esab.se?subject=ESAB manuals site">
        documentation@esab.se
      </Link>
      .
    </Content>
  </FooterContainer>
);
const FooterContainer = styled.div`
  position: relative;
  font-size: 12px;
  color: #aaa;
  text-align: center;
  width: 100%;
  padding: 30px 0 20px 0;
`;

const Link = styled.a`
  color: #666;
`;

export default Footer;
