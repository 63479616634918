import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Content from './styled/Content';
import SearchForm from './SearchForm';
import logo from '../assets/images/logo.png';
import { useStores } from '../context/StoreContext';

const Header = () => {
  const { uiStore } = useStores();
  return (
    <HeaderContainer>
      <Content>
        {uiStore.showLogo && <Logo src={logo} />}
        <FormWrapper logo={uiStore.showLogo}>
          <SearchForm />
        </FormWrapper>
      </Content>
    </HeaderContainer>
  );
};

const Logo = styled.img`
  float: left;
  margin: 3px 40px 10px 0;
  @media screen and (max-width: 600px) {
    float: none;
  }
`;

const FormWrapper = styled.div<{ logo: boolean }>`
  margin-left: ${(props) => (props.logo ? '127px' : '0')};
  overflow: auto;
  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  background: #fee509;
  padding: 15px 0 10px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
`;

export default observer(Header);
